import { cva } from "class-variance-authority";
import { cn } from "~/lib/utils";

interface TextProps {
  variant?: "default" | "bold" | "light" | "annotation";
  children: React.ReactNode;
  className?: string;
  tag?: "p" | "span";
}

const TextVariants = cva("", {
  variants: {
    variant: {
      default: "text-[14px] md:text-[18px] leading-[1.8]",
      bold: "text-[14px] font-medium md:text-[18px] leading-[1.8]",
      light: "text-[14px] leading-[1.8] md:text-[16px]",
      annotation: "text-[13px] leading-[1.6] md:text-[14px] md:leading-[1.8]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Text = ({
  children,
  className,
  variant = "default",
  tag = "p",
  ...props
}: TextProps) => {
  const Tag = tag || "p";
  return (
    <Tag className={cn(TextVariants({ variant, className }))} {...props}>
      {children}
    </Tag>
  );
};

export { Text, type TextProps };
